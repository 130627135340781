import { useCallback, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Form, useFormikContext } from 'formik';
import ImagePreview from '../ImagePreview';
import styles from './ablineForm.module.scss';
import ABLineCreationOptions from './subcomponents/ABLineCreationOptions';
import ABLineAdjustmentOptions from './subcomponents/ABLineAdjustmentOptions';

interface ABLineFormProps {
  imageUrl: string;
  isLoading: boolean;
  task: string;
  generatePreview: (values?: Record<string, any>) => void;
  error: string | null;
}

const ABLineForm = ({
  imageUrl,
  isLoading,
  task,
  generatePreview,
  error,
}: ABLineFormProps) => {
  const { values } = useFormikContext<Record<string, any>>();
  const { trial } = useAppSelector((state) => state.createTrial);

  const generatePreviewIfValid = useCallback(
    (name: string, value?: string) => {
      // this is a temp fix to prevent duplicate api calls
      // something in between the setRadioGroupOption useEffect and the onChange prop
      // of the RadioGroup component is causing this callback to be
      // fired twice when the radio group option is changed
      if (values[name] || value) {
        generatePreview({
          ...values,
          ...(value ? { [name]: value } : {}),
        });
      }
    },
    [generatePreview, values],
  );

  useEffect(() => {
    generatePreviewIfValid('get_abline_method', trial.get_abline_method);
    // Adding both generatePreviewIfValid and trial.get_abline_method
    // to the dependency array is causing extra api calls.
    // In this useEffect context, we only want to generate preview when
    // trial.get_abline_method changes. If we add generatePreviewIfValid to
    // the dependency array, the debounce functions will cause generatePreviewIfValid
    // to change and thus trigger this useEffect again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trial.get_abline_method]);

  return (
    <Form className={styles['abline-form']}>
      {task === 'creation' && (
        <ABLineCreationOptions
          generatePreviewIfValid={generatePreviewIfValid}
          trial={trial}
        />
      )}
      {task === 'adjustment' && (
        <ABLineAdjustmentOptions
          generatePreviewIfValid={generatePreviewIfValid}
        />
      )}
      <ImagePreview imageUrl={imageUrl} isLoading={isLoading} error={error} />
    </Form>
  );
};

export default ABLineForm;
