import styles from '../../ablineForm.module.scss';
import RadioGroup from 'components/global/RadioGroup';
import getOption from 'utils/helpers/getOption';
import FileUploadModal from '../../../FileUploadModal';
import FormElement from 'components/global/FormElement';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface ABLineCreationOptionsProps {
  generatePreviewIfValid: (name: string, value?: string) => void;
  trial: Record<string, any>;
}

const ABLineCreationOptions = ({
  generatePreviewIfValid,
  trial,
}: ABLineCreationOptionsProps) => {
  const [radioGroupOption, setRadioGroupOption] = useState('guess');

  useEffect(() => {
    // set the default AB line method when it's changed
    setRadioGroupOption(trial.get_abline_method);
  }, [trial.get_abline_method]);

  const debouncedCallback = useDebouncedCallback(
    (value: string | undefined) => {
      if (value !== '') {
        generatePreviewIfValid('abline_heading', value);
      }
    },
    750,
  );

  return (
    <div>
      <div
        className={styles['abline-form__method-select']}
        data-testid="abline-form__method-select"
      >
        <RadioGroup
          name="get_abline_method"
          radioGroupHeader={'How do you want to make your starting AB line?'}
          radioGroupFooter={
            'Note: Your starting AB line can be adjusted later.'
          }
          options={[
            getOption(
              trial.field_boundary_gdf === 'demo' ? 'demo' : 'upload',
              trial.field_boundary_gdf === 'demo'
                ? 'Use demo AB line'
                : 'I have an AB line',
            ),
            getOption('heading', 'I have a heading'),
            getOption('guess', "Use FarmTest's initial guess"),
          ]}
          defaultValue={radioGroupOption}
          onChange={(event) => {
            generatePreviewIfValid('get_abline_method', event.target.value);
          }}
        />
      </div>

      {trial.get_abline_method === 'upload' &&
        !(trial.field_boundary_gdf === 'demo') && (
          <FileUploadModal
            name="abline_gdf"
            metaName="abline_files_meta"
            title="AB line"
            fileDescription="Select the AB line shapefile."
            uploadAction={() => {
              generatePreviewIfValid('abline_gdf');
            }}
          />
        )}

      {trial.get_abline_method === 'heading' && (
        <FormElement
          name="abline_heading"
          label="Enter AB line heading in degrees:"
          tooltip="North as Heading = 0, Heading increases clockwise"
          rightElement="°"
          onChangeCallback={(event: {
            target: { value: string | undefined };
          }) => {
            debouncedCallback(event.target.value);
          }}
        />
      )}
    </div>
  );
};

export default ABLineCreationOptions;
